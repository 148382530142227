<template>
    <div class="mobile-select-wrapper" :class="{
        en: locale == 'en',
        ja: locale == 'ja'
    }">
        <select v-model="locale" @change="switchLang">
            <option value="en">English</option>
            <option value="ja">日本語</option>
        </select>
    </div>
</template>
<script>

export default {
    data() {
        return {
            langSelectActive: false,
            locale: null,
        }
    },
    methods: {
        switchLang(event) {
            const lang = event.target.value;
            this.$root.$emit("curtain_run", true);
            setTimeout(() => {
                this.locale = lang;
                this.$i18n.locale = this.locale;
                this.$cookies.set('locale', this.locale);
                window.scrollTo({ top: 0 });
            }, 2000);
            setTimeout(() => this.$root.$emit("curtain_run", false), 5000);
        },
        setLocale() {
            this.$i18n.locale = this.$cookies.get('locale');
            
            if(!this.$i18n.locale) {
                this.$i18n.locale = this.getClientLocale();
            }
            
            this.locale = this.$i18n.locale;

            if(this.$i18n.locale && (this.$i18n.locale != 'en' && this.$i18n.locale != 'ja')) {
                this.locale = "en";
                this.$i18n.locale = "en";
                this.$cookies.set('locale', "en");
            }
        },
        getClientLocale() {
            const userLang = navigator.language || navigator.userLanguage; 
            const locale = userLang.split('-');
            return locale[0];
        }
    },
    mounted() {
       this.setLocale();
       document.addEventListener('click', () => { this.langSelectActive = false });
    }
};
</script>
<style lang="scss" scoped>
@import '../scss/app';
.mobile-select-wrapper {
    position: relative;
    border: 2px solid #fff;
    border-radius: 6px;
    width: 120px;
    padding: 5px 12px 5px 35px;
    
    select {
        -webkit-appearance: none;
        display: block;
        background-color: transparent;
        border: none;
        color: #fff;
        outline: none;
        z-index: 1;
        @include font-size(17px);
        font-family: $family-regular;
    }

    &::after {
        content: '';
        position: absolute;
        background: url('/images/icons/lang-arrow.svg');
        width: 20px;
        height: 20px;
        top: 6.5px;
        right: 5px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 7.5px;
        left: 8px;
    }

    &.en:before {
        background: url('/images/icons/us.svg');
    }

    &.ja:before {
        background: url('/images/icons/jp.svg');
    }
}
</style>